import React from 'react';
import { Link } from 'react-router-dom';

import ContactIcons from '../Contact/ContactIcons';

const { PUBLIC_URL } = process.env; // set automatically from package.json:homepage

const SideBar = () => (
  <section id="sidebar">
    <section id="intro">
      <Link to="/" className="logo">
        <img src={`${PUBLIC_URL}/images/me.jpg`} alt="" />
      </Link>
      <header>
        <h2>Matthieu Rochette</h2>
        <p><a href="mailto:contact@matthieu-rochette.fr">contact@matthieu-rochette.fr</a></p>
      </header>
    </section>

    <section className="blurb">
      <h2>About</h2>
      <p>Hi, I&apos;m Matthieu. I am a Python and C#/.NET developer and DevOps engineer. I just finished my studies at <a href="https://www.epitech.eu">Epitech</a>. During the last 5 years, I learned about Software and Web Development, as well as DevOps, both through my studies and through various internships, which you can learn about in my <a href="/resume">resume</a>. I also have done a number of projects (some of which are still ongoing), feel free to discover them on the <a href="/projects">projects</a> page.</p>
      <ul className="actions">
        <li>
          <Link to="/resume" className="button">Learn More</Link>
        </li>
      </ul>
    </section>

    <section id="footer">
      <ContactIcons />
      <p className="copyright">&copy; Matthieu Rochette <Link to="/">www.matthieu-rochette.fr</Link>.</p>
    </section>
  </section>
);

export default SideBar;
