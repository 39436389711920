import { faGithub } from '@fortawesome/free-brands-svg-icons/faGithub';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons/faLinkedinIn';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons/faEnvelope';
import { faGitlab } from '@fortawesome/free-brands-svg-icons/faGitlab';
import { faUnsplash } from '@fortawesome/free-brands-svg-icons/faUnsplash';
// See https://fontawesome.com/icons?d=gallery&s=brands,regular&m=free
// to add other icons.

const data = [
  {
    link: 'https://github.com/MatthieuRochette',
    label: 'Github',
    icon: faGithub,
  },
  {
    link: 'https://gitlab.com/MatthieuRochette',
    label: 'Gitlab',
    icon: faGitlab,
  },
  {
    link: 'https://www.instagram.com/matthieur68_2/',
    label: 'Instagram',
    icon: faInstagram,
  },
  {
    link: 'https://www.linkedin.com/in/matthieu-rochette',
    label: 'LinkedIn',
    icon: faLinkedinIn,
  },
  {
    link: 'mailto:contact@matthieu-rochette.fr',
    label: 'Email',
    icon: faEnvelope,
  },
  {
    link: 'https://unsplash.com/@matthieur68',
    label: 'Unsplash',
    icon: faUnsplash,
  },
];

export default data;
